@import url('https://rsms.me/inter/inter.css');

:root {
  --c-bg: hsl(227, 64%, 4%);
  --c-bg-card: hsl(227, 14%, 13%);
  --c-text-primary: hsla(0, 0%, 100%, 1);
  --c-text-secondary: hsla(0, 0%, 100%, 0.72);
  --c-text-hint: hsla(0, 0%, 100%, 0.5);
  --c-text-error: hsl(0, 100%, 50%);
  --c-text-success: hsl(120, 94%, 49%);

  --c-btn-bg: hsla(0, 0%, 100%, 0.12);
  --c-btn-bg-hover: hsla(0, 0%, 100%, 0.16);
  --c-btn-bg-active: hsla(0, 0%, 100%, 0.24);
  --c-divider: hsla(0, 0%, 100%, 0.1);

  --c-scrollbar: hsla(0, 0%, 100%, 0.16);

  --radius-m: 6px;
  --radius-l: 24px;

  --canvas-filter: invert(1);
}

/* CSS Reset from https://piccalil.li/blog/a-modern-css-reset */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd,
button {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

html {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: var(--c-text-primary);
  background: var(--c-bg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-corner {
  background: var(--c-bg);
}

::-webkit-scrollbar-thumb {
  background-color: var(--c-scrollbar);
  border-radius: 6px;
  border: 2px solid var(--c-bg);
}
